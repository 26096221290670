// Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { Icon } from '@iconify/react';

import { spribeGameList, spribeLaunch, spribeOperatorKey } from 'config/constant';
import useSettings from 'hooks/useSettings';
import useAuth from 'hooks/useAuth';

type IGame = {
    icon: string;
    title: string;
    game: string;
};

const GameList = () => {
    const { onChangeModal } = useSettings();
    const { isLogined, user, accessToken } = useAuth();
    const { onChangeGameModal } = useSettings();

    const handleLaunch = (game: IGame) => {
        if (accessToken && isLogined) {
            onChangeGameModal({
                open: true,
                title: game.title,
                link: `${spribeLaunch}/${game.game}?user=${user._id}&token=${accessToken}&lang=en&currency=INR&operator=${spribeOperatorKey}&return_url=https://hattrick247.com/spribe`
            });
        }
    };

    return (
        <Grid container spacing={2}>
            {spribeGameList.map((game: IGame, i: number) => (
                <Grid item md={12 / 5} sm={12 / 4} xs={6} key={i}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            borderRadius: 2,
                            overflow: 'hidden',
                            cursor: 'pointer',
                            position: 'relative'
                        }}
                    >
                        <Stack>
                            <div
                                style={{
                                    paddingBottom: '60%',
                                    backgroundImage: `url(${game.icon})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                            <Stack sx={{ py: 2, px: 1, bgcolor: (theme) => theme.palette.background.paper }}>
                                <Typography sx={{ fontSize: 12, opacity: 0.8 }}>Crash Game</Typography>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{game.title}</Typography>
                                    {/* <Icon icon="iconamoon:star-fill" color="white" width="18" height="18" /> */}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                transition: 'opacity 0.5s',
                                zIndex: 9,
                                opacity: 0,
                                bgcolor: '#535353b0',
                                '&:hover': {
                                    opacity: 1
                                }
                            }}
                        >
                            {isLogined ? (
                                <Button onClick={() => handleLaunch(game)} variant="contained" color="primary">
                                    Play
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => onChangeModal({ loginModal: true })}
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'primary.light'
                                        }
                                    }}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default GameList;
