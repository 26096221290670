import { useState } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
// hook
import useSettings from 'hooks/useSettings';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useSnackbar } from 'notistack';
import { useBoolean } from 'hooks/use-boolean';
import { resetPassword, sendPasswordOTP } from 'utils/apis';
import { grey } from '@mui/material/colors';

type FormValuesProps = {
    phone: string;
    password: string;
};

const ForgotPassword = () => {
    const { onChangeModal } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    const isVerify = useBoolean();
    const otpPage = useBoolean();
    const hidePassword = useBoolean(true);

    const [otp, setOtp] = useState('');

    const ResetPasswordSchema = Yup.object().shape({
        phone: Yup.string().required('Phone Number is required').min(10).max(15),
        password: Yup.string().required('Password is required')
    });

    const defaultValues = {
        phone: '',
        password: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(ResetPasswordSchema),
        defaultValues
    });

    const {
        watch,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    const value = watch();

    const onSubmit = handleSubmit(async (data) => {
        try {
            await sendPasswordOTP({ phone: data.phone.trim() });
            otpPage.onTrue();
            return;
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
        }
    });

    const otpResend = async () => {
        try {
            if (!value.phone) {
                enqueueSnackbar('Phone Number required', { variant: 'error' });
                return;
            }
            await sendPasswordOTP({ phone: value.phone.trim() });
            enqueueSnackbar('Resent Successfully', { variant: 'success' });
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
        }
    };

    const otpVerify = async () => {
        try {
            if (!otp) return;
            isVerify.onTrue();
            await resetPassword({
                phone: value.phone.trim(),
                password: value.password,
                otp
            });
            isVerify.onFalse();
            enqueueSnackbar('Succss!', { variant: 'success' });
            onChangeModal({ loginModal: true, forgotModal: false });
        } catch (error: any) {
            const errMessage = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(errMessage, { variant: 'error' });
            isVerify.onFalse();
        }
    };

    const otpInput = (
        <Stack spacing={2} sx={{ mb: 2 }}>
            <TextField
                variant="filled"
                label="OTP Code"
                value={otp}
                sx={{
                    width: '100%',
                    borderRadius: 4,
                    overflow: 'hidden',
                    '& .MuiInputBase-root': {
                        borderRadius: 4,
                        border: '1px solid',
                        borderColor: grey[400]
                    }
                }}
                onChange={(e: any) => setOtp(e.target.value)}
                inputProps={{
                    autoComplete: 'off'
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon icon="fa6-solid:comment-sms" color="#637381" width="18" height="18" />
                        </InputAdornment>
                    )
                }}
            />
            <Box>
                <Button size="small" variant="contained" color="inherit" onClick={otpResend} sx={{ borderRadius: 2 }}>
                    <Icon icon="icon-park-twotone:timer" color="#637381" width="18" height="18" />
                    <Typography variant="body2">Resend</Typography>
                </Button>
            </Box>
            <Stack direction="row" spacing={3}>
                <Button
                    color="warning"
                    variant="contained"
                    sx={{
                        width: 1,
                        borderRadius: 4,
                        background: (theme) =>
                            `linear-gradient(138.38deg, ${theme.palette.warning.light} 12.51%, ${theme.palette.warning.dark} 154.03%)`
                    }}
                    onClick={otpPage.onFalse}
                >
                    Back
                </Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    sx={{
                        width: 1,
                        borderRadius: 4,
                        background: (theme) =>
                            `linear-gradient(138.38deg, ${theme.palette.success.light} 12.51%, ${theme.palette.success.dark} 154.03%)`
                    }}
                    loading={isVerify.value}
                    onClick={otpVerify}
                >
                    Verify
                </LoadingButton>
            </Stack>
        </Stack>
    );

    const renderInputs = (
        <>
            <Stack rowGap={3} columnGap={2} display="grid" width="100%" gridTemplateColumns="repeat(1, 1fr)">
                <RHFTextField
                    fullWidth
                    variant="filled"
                    name="phone"
                    label="Phone Number"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="ic:baseline-phone" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        )
                    }}
                />
                <RHFTextField
                    fullWidth
                    variant="filled"
                    type={hidePassword.value ? 'password' : 'text'}
                    name="password"
                    label="New Password"
                    sx={{
                        width: '100%',
                        borderRadius: 4,
                        overflow: 'hidden',
                        '& .MuiInputBase-root': {
                            borderRadius: 4,
                            border: '1px solid',
                            borderColor: grey[400]
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="mdi:password" color="#637381" width="18" height="18" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={hidePassword.onToggle}>
                                    <Icon
                                        icon={hidePassword.value ? 'mdi:eye-off' : 'mdi:eye'}
                                        color="#637381"
                                        width="23"
                                        height="23"
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
            <LoadingButton
                loading={isSubmitting}
                size="large"
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                    my: 2,
                    borderRadius: 4,
                    background: `linear-gradient(138.38deg, #6D96FC 12.51%, #0134A9 154.03%)`
                }}
            >
                Reset Password
            </LoadingButton>
        </>
    );

    return (
        <Stack spacing={2} justifyContent="center" sx={{ width: { sm: '50%', xs: '100%' } }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                {otpPage.value ? otpInput : renderInputs}
                <Typography component="div" sx={{ color: 'action.active', textAlign: 'center', userSelect: 'none' }}>
                    Go to &nbsp;
                    <Typography
                        component="span"
                        onClick={() => onChangeModal({ forgotModal: false, loginModal: true })}
                        noWrap
                        sx={{ fontWeight: 'bold', fontSize: 14, color: 'primary.light', cursor: 'pointer' }}
                    >
                        Login
                    </Typography>
                </Typography>
            </FormProvider>
        </Stack>
    );
};

export default ForgotPassword;
