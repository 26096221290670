import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    ThemeOptions,
    StyledEngineProvider,
    ThemeProvider as MUIThemeProvider
} from '@mui/material/styles';

import palette, { customBreakpoints } from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
// hooks
import useSettings from 'hooks/useSettings';

import 'swiper/swiper.min.css';
// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
    const { mode } = useSettings();
    const themeDirection = 'ltr';
    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: palette(mode),
            typography,
            shape: { borderRadius: 4 },
            direction: themeDirection,
            shadows: shadows(mode),
            customShadows: customShadows(mode),
            breakpoints: customBreakpoints
        }),
        [themeDirection, mode]
    );

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
