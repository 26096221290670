import { useMemo, useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';

import { SettingContext, defaultSettings } from 'contexts/setting';
import useLocalStorage from 'hooks/useLocalStorage';
import { IOnlineConfig, ModeValue } from 'types/config';

type SettingProviderProps = {
    children: React.ReactNode;
};

type IOnlineData = {
    configured: boolean;
    avaialbe: boolean;
    config: IOnlineConfig | null;
};

const SettingProvider: React.FC<SettingProviderProps> = ({ children }) => {
    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
    const [onlineData, setOnlineData] = useState<IOnlineData>({
        configured: false,
        avaialbe: true,
        config: null
    });
    const [game, setGame] = useState({
        open: false,
        title: '',
        link: ''
    });

    const onChangeTheme = useCallback(
        (mode: ModeValue) => {
            setSettings({ ...settings, mode });
        },
        [setSettings, settings]
    );

    // Layout
    const onChangeNav = useCallback(() => {
        const navbar = settings.navbar === 'open' ? 'close' : 'open';
        setSettings({ ...settings, navbar });
    }, [setSettings, settings]);

    const onChangeModal = useCallback(
        (status: any) => {
            setSettings({ ...settings, ...status });
        },
        [setSettings, settings]
    );

    const onChangeGameModal = useCallback(
        (gameData: { open: boolean; title: string; link: string }) => {
            setGame({ ...gameData });
        },
        [setGame]
    );

    const onChangePasswordModal = useCallback(
        (status: any) => {
            const passwordModal = typeof status === 'boolean' ? status : !settings.passwordModal;
            setSettings({ ...settings, passwordModal });
        },
        [setSettings, settings]
    );

    // INIT
    const getSite = useCallback(async () => {
        try {
            const response = await axios.get('api/settings/config');
            if (response.data) {
                if (response.data.status) {
                    setOnlineData({
                        configured: true,
                        avaialbe: true,
                        config: response.data.data
                    });
                } else {
                    setOnlineData({
                        configured: true,
                        avaialbe: false,
                        config: null
                    });
                }
            }
        } catch (error) {
            setOnlineData({
                configured: true,
                avaialbe: false,
                config: null
            });
        }
    }, []);

    useEffect(() => {
        getSite();
    }, [getSite]);

    const memoizedValue = useMemo(
        () => ({
            ...settings,
            game,
            configured: onlineData.configured,
            avaialbe: onlineData.avaialbe,
            config: onlineData.config,
            onChangeNav,
            onChangeTheme,
            onChangeGameModal,
            onChangeModal,
            onChangePasswordModal
        }),
        [game, onlineData, onChangeNav, onChangeModal, onChangePasswordModal, onChangeGameModal]
    );

    return <SettingContext.Provider value={memoizedValue}>{children}</SettingContext.Provider>;
};

export default SettingProvider;
