import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
// hook
import useSettings from 'hooks/useSettings';
// util
import { ASSETS } from 'utils/axios';
import logoIcon from 'assets/img/logo/logo.png';
import UserPart from './UserPart';

const MobileHeader = () => {
    const { navbar, onChangeNav, config } = useSettings();

    return (
        <Stack
            direction="row"
            alignItems="center"
            component="header"
            sx={{
                top: 0,
                height: 60,
                zIndex: 100,
                width: '100%',
                position: 'fixed',
                bgcolor: 'background.default'
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, width: '100%' }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton sx={{ p: 0 }} onClick={onChangeNav}>
                        {navbar === 'close' ? (
                            <Icon icon="mdi:menu-close" color="#ffab00" width="30" height="30" />
                        ) : (
                            <Icon icon="ic:twotone-menu-open" color="#ffab00" width="30" height="30" />
                        )}
                    </IconButton>
                    <RouterLink to="/sports">
                        <Box
                            component="img"
                            src={config && config.domain.logo ? ASSETS(config?.domain.logo) : logoIcon}
                            alt="logo"
                            sx={{ maxWidth: { sm: 170, xs: 130 }, maxHeight: 40 }}
                        />
                    </RouterLink>
                </Stack>
                <UserPart />
            </Stack>
        </Stack>
    );
};

export default MobileHeader;
