import { createSlice } from '@reduxjs/toolkit';
// @types
import { IBetSlipProps } from 'types/bet';

// ----------------------------------------------------------------------

const initialState: IBetSlipProps = {
    isLoading: false,
    betting: false,
    slip: null,
    profitLoss: {},
    cashoutPL: {},
    openbets: []
};

const betSlice = createSlice({
    name: 'betSlip',
    initialState,
    reducers: {
        setSlip(state, action) {
            state.slip = action.payload;
        },
        updateSlip(state, action) {
            if (state.slip) {
                state.slip = { ...state.slip, ...action.payload };
            }
        },
        removeSlip(state) {
            state.slip = null;
        },
        updateSlipLoading(state) {
            state.isLoading = !state.isLoading;
        },
        setProfitLoss(state, action) {
            state.profitLoss = { ...state.profitLoss, ...action.payload };
        },
        setCashoutPL(state, action) {
            state.cashoutPL = { ...state.cashoutPL, ...action.payload };
        },
        setOpenBets(state, action) {
            state.openbets = action.payload;
        },
        clearProfitLoss(state) {
            state.profitLoss = {};
        },
        clearCashoutPL(state) {
            state.cashoutPL = {};
        },
        setBetting(state, action) {
            state.betting = action.payload;
        }
    }
});

export const {
    setSlip,
    updateSlip,
    removeSlip,
    updateSlipLoading,
    setProfitLoss,
    clearProfitLoss,
    setOpenBets,
    setBetting,
    setCashoutPL,
    clearCashoutPL
} = betSlice.actions;
// Reducer
export default betSlice.reducer;
