import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import { getBetHistory } from 'utils/apis';
import { PageNationPerPage } from 'config/constant';
import { useSnackbar } from 'notistack';
import { BackButton, LayButton } from 'components/Styles';

import { Icon } from '@iconify/react';

import { PagenationProps } from 'types/bet';
import useAuth from 'hooks/useAuth';
import Pagenation from 'components/Pagenation';

const BetHistory = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    const [data, setData] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const [parsedFilter, setparsedFilter] = useState<PagenationProps>({ page: 0, perPage: PageNationPerPage[0] });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setparsedFilter((pre: PagenationProps) => ({ ...pre, perPage: parseInt(event.target.value, 10) }));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setparsedFilter((pre: PagenationProps) => ({ ...pre, page: newPage }));
    };

    /*eslint-disable */
    const limitload = useCallback(async () => {
        try {
            const provider = searchParams.get('provider') || '';
            const marketId = searchParams.get('marketId') || '';
            const marketName = searchParams.get('marketName') || '';
            const start = searchParams.get('start') || '';
            const end = searchParams.get('end') || '';

            if (!marketId || !provider) {
                return navigate('/reports/profit-loss');
            }
            const condition: any = {
                parsedFilter,
                provider,
                marketId,
                age: 'past',
                marketName,
                userId: user._id
            };
            if (start && end) {
                condition.date = { start: start, end: end };
            }

            let rdata = await getBetHistory(condition);
            if (rdata) {
                const totalRecords = rdata.pageset.totalRecords;
                setData(rdata.data);
                setTotalRecords(totalRecords);
            }
        } catch (error: any) {
            const message = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(message, { variant: 'error' });
        }
    }, [parsedFilter]);

    useEffect(() => {
        limitload();
    }, [limitload]);
    /*eslint-enable */

    return (
        <Box sx={{ p: 1, my: 1, borderRadius: 1, bgcolor: 'background.paper' }}>
            <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" sx={{ pl: 2 }} spacing={2}>
                        <Icon icon="mdi:book-alphabet" width="32" height="32" />
                        <Typography sx={{ fontWeight: 600 }} variant="h5">
                            Bets History
                        </Typography>
                    </Stack>
                </Stack>
                <Box sx={{ overflowX: 'auto' }}>
                    <Table
                        size="small"
                        sx={{
                            minWidth: 960,
                            borderCollapse: 'separate',
                            borderSpacing: '0 16px',
                            borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Bet Id</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Bet Type</TableCell>
                                <TableCell>Odds</TableCell>
                                <TableCell>Stake</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>P/L</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((oneBet: any) => (
                                <TableRow key={oneBet.betId}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{oneBet.betId}</TableCell>
                                    <TableCell>{oneBet.description}</TableCell>
                                    <TableCell>
                                        {oneBet.betType === 'back' ||
                                        oneBet.betType === 'yes' ||
                                        oneBet.betType === 'credit' ||
                                        oneBet.betType === 'promo_credit' ? (
                                            <BackButton
                                                color="info"
                                                sx={{
                                                    fontSize: 12,
                                                    lineHeight: 1,
                                                    color: '#000',
                                                    fontWeight: 600
                                                }}
                                            >
                                                {oneBet.betType}
                                            </BackButton>
                                        ) : (
                                            <LayButton
                                                color="error"
                                                sx={{
                                                    fontSize: 12,
                                                    lineHeight: 1,
                                                    color: '#000',
                                                    fontWeight: 600
                                                }}
                                            >
                                                {oneBet.betType}
                                            </LayButton>
                                        )}
                                    </TableCell>
                                    <TableCell>{oneBet.price}</TableCell>
                                    <TableCell>{oneBet.stake}</TableCell>
                                    <TableCell>{moment(oneBet.date).format('MM/DD/yyyy hh:mm:ss')}</TableCell>
                                    <TableCell>{oneBet.betType === 'lay' ? oneBet.loss : oneBet.profit}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <Pagenation
                    count={totalRecords}
                    rowsPerPage={parsedFilter.perPage}
                    page={parsedFilter.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Stack>
        </Box>
    );
};

export default BetHistory;
