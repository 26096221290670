import { useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// hook
import useSettings from 'hooks/useSettings';
// type
import { IBannerItem } from 'types/config';
// util
import { ASSETS } from 'utils/axios';
import { Link } from 'react-router-dom';

const Slider = () => {
    const { config } = useSettings();

    const banners = useMemo(() => {
        if (config && config.banners) {
            return config.banners.filter((banner) => banner.type === 'slider');
        }
        return [];
    }, [config]);

    if (banners.length) {
        return (
            <Box sx={{ borderRadius: 2, overflow: 'hidden', height: '100%' }}>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    loop={true}
                    spaceBetween={0}
                    slidesPerView={1}
                >
                    {banners.map((banner: IBannerItem, i: number) => (
                        <SwiperSlide key={i}>
                            {banner.redirectUrl ? (
                                <Link to={banner.redirectUrl}>
                                    <Box
                                        component="img"
                                        src={ASSETS(banner.image)}
                                        alt="slider"
                                        sx={{ width: '100%', maxHeight: { md: 500, xs: 200 }, userSelect: 'unset' }}
                                    />
                                </Link>
                            ) : (
                                <Box
                                    component="img"
                                    src={ASSETS(banner.image)}
                                    alt="slider"
                                    sx={{ width: '100%', maxHeight: { md: 500, xs: 200 }, userSelect: 'unset' }}
                                />
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        );
    }
    return null;
};

export default Slider;
