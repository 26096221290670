// @mui
import { Box, BoxProps } from '@mui/material';
// hooks
import useSetting from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';

// config
import { NAV } from 'config/constant';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import MobileHeader from 'components/Header/MobileHeader';
// import Marquee from 'components/marquee';
import MobileMenu from 'components/MobileMenu';
import RightPanel from './RightPanel';
import UserWrapper from './UserWrapper';
// ----------------------------------------------------------------------

export function Main({ children, sx, ...other }: BoxProps) {
    return (
        <Box
            component="main"
            sx={{
                mt: 2,
                transition: 'width 0.5s',
                flexGrow: 1,
                width: '100%',
                ...sx
            }}
            {...other}
        >
            {children}
        </Box>
    );
}

const ProfileLayout = () => {
    const { navbar, onChangeNav } = useSetting();

    const isDesktop = useResponsive('up', 'md');
    const isLg = useResponsive('up', 'lg');

    return (
        <>
            {isDesktop ? <Header /> : <MobileHeader />}
            <Box sx={{ mt: { md: '70px', xs: '60px' } }}>
                {/* <Marquee /> */}
                <Box sx={{ display: 'flex' }}>
                    <Navbar openNav={navbar === 'open'} onCloseNav={onChangeNav} />
                    <Main>
                        <Box
                            sx={{
                                transition: 'padding 0.5s',
                                pr: `${isLg ? NAV.W_RIGHT_PANEL + 32 : !isDesktop ? 8 : 16}px`,
                                pl: `${
                                    !isDesktop
                                        ? 8
                                        : navbar === 'open'
                                        ? NAV.W_DASHBOARD + 32
                                        : NAV.W_DASHBOARD_MINI + 32
                                }px`
                            }}
                        >
                            <UserWrapper />
                            <Footer />
                        </Box>
                        {isLg && <RightPanel />}
                    </Main>
                </Box>
            </Box>
            <MobileMenu />
        </>
    );
};

export default ProfileLayout;
