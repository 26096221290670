import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'utils/axios';
import { AuthContext, RegisterValue } from 'contexts/auth';
import { getUniqueArray, setSession } from 'utils/auth';
import localStorageAvailable from 'utils/localStorageAvailable';
import useSettings from 'hooks/useSettings';

import { loginAction, logoutAction, updateStakesAction, updateUserAction } from 'redux/slices/auth';

type AuthProviderProps = {
    children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const dispatch = useDispatch();
    const store = useSelector((state: any) => state.auth);
    const { onChangeModal } = useSettings();
    const storageAvailable = localStorageAvailable();

    const closeModal = () => {
        onChangeModal({ loginModal: false, registerModal: false, forgotModal: false });
    };

    const initialize = useCallback(async () => {
        try {
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken) {
                setSession(accessToken);
                closeModal();
                const response = await axios.post('/api/auth/me');
                const { user } = response.data;
                let permissions = {};
                Object.keys(user.permissions.permission).map((key) => {
                    permissions[key] = user.permissions.permission[key] && user.permissions.headerpermission[key];
                    return true;
                });

                const me = { ...user, permissions };
                const disabledMatch = await getUniqueArray(
                    user.permissions.disabledMatch,
                    user.permissions.headerDisabledMatch
                );
                dispatch(loginAction({ user: me, accessToken, disabledMatch }));
            } else {
                dispatch(logoutAction());
            }
        } catch (error) {
            setSession(null);
            dispatch(logoutAction());
        }
    }, [storageAvailable]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = useCallback(async (username: string, password: string, remember: boolean) => {
        const response = await axios.post('/api/auth/login', {
            username,
            password
        });
        const { accessToken, user } = response.data;
        if (remember) {
            localStorage.setItem('hattrick247', JSON.stringify({ username, password }));
        } else {
            localStorage.setItem('hattrick247', '');
        }
        let permissions = {};
        Object.keys(user.permissions.permission).map((key) => {
            permissions[key] = user.permissions.permission[key] && user.permissions.headerpermission[key];
            return true;
        });
        const disabledMatch = getUniqueArray(user.permissions.disabledMatch, user.permissions.headerDisabledMatch);
        const me = { ...user, permissions };
        setSession(accessToken);
        dispatch(loginAction({ user: me, accessToken, disabledMatch }));
        closeModal();
    }, []);

    // REGISTER
    const register = useCallback(async (registerValue: RegisterValue) => {
        const response = await axios.post('/api/auth/register', registerValue);
        const { accessToken } = response.data;

        localStorage.setItem('accessToken', accessToken);
    }, []);

    // UPDATE STAKES
    const updateStakes = useCallback(async (stakes: { name: string; value: number }[]) => {
        dispatch(updateStakesAction(stakes));
    }, []);

    // UPDATE USER
    const updateUser = useCallback(async (params: { kyc: boolean }) => {
        dispatch(updateUserAction(params));
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        try {
            const response = await axios.post('api/auth/logout');
            if (response.data) {
                setSession(null);
                dispatch(logoutAction());
                window.location.href = '/';
            }
        } catch (error) {
            console.log(error);
            setSession(null);
            dispatch(logoutAction());
            window.location.href = '/';
        }
    }, []);

    const memoizedValue = useMemo(
        () => ({
            authLoading: store.authLoading,
            accessToken: store.accessToken,
            user: store.user,
            blockList: store.blockList,
            disabledMatch: store.disabledMatch,
            stakes: store.stakes,
            isLogined: store.isLogined,
            login,
            register,
            logout,
            updateUser,
            updateStakes
        }),
        [store, login, logout, register, updateUser, updateStakes]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
