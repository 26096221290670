import * as React from 'react';

import Iconify from 'components/iconify';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useSettings from 'hooks/useSettings';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GameDialog() {
    const { game, onChangeGameModal } = useSettings();

    const handleClose = () => {
        onChangeGameModal({
            open: false,
            title: '',
            link: ''
        });
    };

    return (
        <Dialog
            fullScreen
            open={game.open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{ '.MuiDialog-paperFullScreen': { m: 0 } }}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {game.title}
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <Iconify icon="ic:twotone-close" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <iframe id="st8" title="st8" width="100%" style={{ height: '100%', border: 0 }} src={game.link} />
        </Dialog>
    );
}
