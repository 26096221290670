// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';

// hooks
import useSettings from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';
// config
import { NAV, headerList } from 'config/constant';

import { Icon } from '@iconify/react';
import NavItem from './NavItem';
// components

// ----------------------------------------------------------------------

type Props = {
    openNav: boolean;
    onCloseNav: VoidFunction;
};

export default function Navbar({ openNav, onCloseNav }: Props) {
    const isDesktop = useResponsive('up', 'md');
    const { mode, onChangeTheme } = useSettings();

    const renderContent = (
        <Box>
            {!isDesktop && (
                <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ p: 0.75, borderRadius: 2, width: 1, bgcolor: 'background.paper', overflow: 'hidden', mb: 2 }}
                >
                    <Icon icon="twemoji:sun" width={24} height={24} />
                    <Switch
                        value={mode === 'light'}
                        onChange={() => onChangeTheme(mode === 'light' ? 'dark' : 'light')}
                    />
                    <Icon icon="line-md:sunny-filled-loop-to-moon-filled-loop-transition" width={24} height={24} />
                </Stack>
            )}
            <Stack
                spacing={1}
                sx={{ p: 0.75, borderRadius: 2, bgcolor: 'background.paper', overflow: 'hidden', mb: 2 }}
            >
                <NavItem
                    openNav={openNav}
                    item={{
                        title: 'Live',
                        path: '/sports/inplay',
                        icon: <Icon icon="fluent:live-24-filled" width={24} height={24} color="#18ac27" />
                    }}
                />
                <NavItem
                    openNav={openNav}
                    item={{
                        title: 'Upcoming',
                        path: '/sports',
                        icon: <Icon icon="mingcute:time-line" width={24} height={24} />
                    }}
                />
                {/* <NavItem
                    openNav={openNav}
                    item={{
                        title: 'Favourite',
                        icon: <Icon icon="noto:star" width={24} height={24} color="#fdd835" />
                    }}
                /> */}
            </Stack>
            <Stack
                spacing={1}
                sx={{ p: 0.75, borderRadius: 2, bgcolor: 'background.paper', overflow: 'hidden', mb: 2 }}
            >
                {headerList.map((item: { title: string; icon: string; link: string }, j: number) => (
                    <NavItem
                        key={j}
                        openNav={openNav}
                        item={{
                            title: item.title,
                            path: item.link,
                            count: 0,
                            icon: <Box component="img" src={item.icon} alt="sport" width={18} height={18} mx={1} />
                        }}
                    />
                ))}
            </Stack>
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={{
                position: 'fixed',
                mt: '86px',
                top: 0,
                left: isDesktop ? 16 : -NAV.W_DASHBOARD_MINI,
                bottom: 16,
                borderRadius: 2,
                overflowX: 'hidden',
                overflowY: 'auto',
                zIndex: 1,
                transition: 'width 0.5s',
                width: openNav ? NAV.W_DASHBOARD : NAV.W_DASHBOARD_MINI
            }}
        >
            {isDesktop ? (
                <Stack
                    sx={{
                        transition: 'width 0.5s',
                        width: openNav ? NAV.W_DASHBOARD : NAV.W_DASHBOARD_MINI,
                        bgcolor: 'background.default'
                    }}
                >
                    {renderContent}
                </Stack>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        sx: {
                            height: '100vh',
                            p: 1,
                            bgcolor: 'background.default',
                            width: NAV.W_DASHBOARD + 32
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
