// @mui
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

export default function Unavailable() {
    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                zIndex: 99999999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Alert severity="warning">Sorry, this domain is not avaialbe now!</Alert>
        </Box>
    );
}
