import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// config
import { NAV } from 'config/constant';
// types
import { st8GameProp } from 'types/casino';
// hooks
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
import { useBoolean } from 'hooks/use-boolean';
import useResponsive from 'hooks/useResponsive';
// utils
import { gameLaunch } from 'utils/apis';

const RightPanel = () => {
    const { isLogined, accessToken } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const isDesktop = useResponsive('up', 'md');
    const { onChangeModal, onChangeGameModal, config } = useSettings();
    const isLive = useBoolean(true);

    const gameList = useMemo(() => {
        if (config?.homeCasino) {
            return config?.homeCasino.filter((item) => {
                if (isLive.value) {
                    return item.category.startsWith('Live');
                } else {
                    return !item.category.startsWith('Live');
                }
            });
        }
        return [];
    }, [config, isLive]);

    const openGame = async (game: st8GameProp) => {
        try {
            if (isLogined) {
                const launchData = await gameLaunch({
                    token: accessToken,
                    game_code: game.code,
                    device: isDesktop ? 'DESKTOP' : 'MOBILE',
                    fun_mode: false
                });
                if (launchData.status === 'ok') {
                    onChangeGameModal({
                        open: true,
                        title: game.name,
                        link: launchData.game_url
                    });
                } else {
                    enqueueSnackbar(launchData.reason, { variant: 'error' });
                }
            } else {
                onChangeModal({ loginModal: true });
            }
        } catch (error: any) {
            enqueueSnackbar(typeof error === 'string' ? error : error.message, { variant: 'error' });
        }
    };

    return (
        <Stack
            sx={{
                position: 'fixed',
                mt: '86px',
                top: 0,
                bottom: 16,
                right: 16,
                borderRadius: 2,
                overflowX: 'auto',
                zIndex: 1,
                width: NAV.W_RIGHT_PANEL
            }}
        >
            <Stack spacing={2}>
                <Stack sx={{ borderRadius: 2, bgcolor: 'background.paper', overflow: 'hidden' }}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Button
                            sx={{
                                flex: 1,
                                borderRadius: 0,
                                py: 1,
                                color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light')
                            }}
                        >
                            Promotion
                        </Button>
                    </Stack>
                    {/* <Stack sx={{ p: 1 }}></Stack> */}
                </Stack>
                <Stack sx={{ borderRadius: 2, bgcolor: 'background.paper', overflow: 'hidden' }}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* <Button sx={{ flex: 1, borderRadius: 0, py: 1 }}>Providers</Button>
                        <Divider orientation="vertical" sx={{ height: '60%' }} /> */}
                        <Button
                            sx={{
                                flex: 1,
                                borderRadius: 0,
                                py: 1,
                                color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light')
                            }}
                            onClick={isLive.onTrue}
                        >
                            Live
                        </Button>
                        <Divider orientation="vertical" sx={{ height: '60%' }} />
                        <Button
                            sx={{
                                flex: 1,
                                borderRadius: 0,
                                py: 1,
                                color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light')
                            }}
                            onClick={isLive.onFalse}
                        >
                            Casino
                        </Button>
                    </Stack>
                    <Stack
                        sx={{ p: 1, display: 'grid', gridTemplateColumns: 'repeat(2, 2fr)', columnGap: 1, rowGap: 1 }}
                    >
                        {gameList.map((game: st8GameProp, i: number) => (
                            <Box
                                key={i}
                                sx={{
                                    height: 'auto',
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                    float: 'left',
                                    borderRadius: 4,
                                    transision: 'all 0.4s ease-out',
                                    zIndex: 1,
                                    position: 'relative',
                                    '&:hover': {
                                        '& .banner-game-item': {
                                            opacity: 1,
                                            transform: 'translateY(10px)'
                                        },
                                        '&::before': {
                                            opacity: 0.8,
                                            position: 'absolute',
                                            content: `''`,
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: 0,
                                            transition: 'all .3s cubic-bezier(.645,.045,.355,1)',
                                            backgroundImage: 'linear-gradient(#3f3f4f 10%,#000 100%)'
                                        }
                                    }
                                }}
                            >
                                <Box
                                    component="img"
                                    src={`https://luckmedia.link/${game.code}/thumb_4_3.webp`}
                                    alt="game"
                                />
                                <Box
                                    className="banner-game-item"
                                    sx={{
                                        transition: '0.7s',
                                        position: 'absolute',
                                        top: '28%',
                                        width: '100%',
                                        textAlign: 'center',
                                        height: 16,
                                        opacity: 0,
                                        transform: 'translate(-50%,-50%)'
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: 'common.white',
                                            fontSize: 10,
                                            fontWeight: 600,
                                            zIndex: 2,
                                            textTransform: 'uppercase'
                                        }}
                                        onClick={() => openGame(game)}
                                    >
                                        {game.name}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default RightPanel;
