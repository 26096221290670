import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';

// hook
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
// utils
import { useRouter } from 'hooks/use-router';
import { useBoolean } from 'hooks/use-boolean';
//
import { grey } from '@mui/material/colors';

type FormValuesProps = {
    username: string;
    password: string;
    afterSubmit?: string;
};

const LoginForm = () => {
    const { onChangeModal, config } = useSettings();
    const { login } = useAuth();
    const router = useRouter();

    const remeberMe = useBoolean();
    const showPassword = useBoolean();

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    const defaultValues = {
        username: '',
        password: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        control,
        setValue,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            await login(data.username, data.password, remeberMe.value);
            router.push('/sports');
        } catch (error: any) {
            setError('afterSubmit', {
                ...error,
                message: error.message || error
            });
        }
    };

    useEffect(() => {
        const rememberString = localStorage.getItem('hattrick247');
        if (rememberString) {
            const rememberData = JSON.parse(rememberString);
            if (rememberData.username) {
                setValue('username', rememberData.username);
            }
            if (rememberData.password) {
                setValue('password', rememberData.password);
            }
            remeberMe.onTrue();
        }
    }, [setValue]);

    return (
        <Stack spacing={2} component="form" sx={{ width: { sm: '50%', xs: '100%' } }} onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="username"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        fullWidth
                        error={!!error}
                        helperText={error ? error?.message : ''}
                        variant="filled"
                        label="Enter your Username"
                        sx={{
                            width: '100%',
                            borderRadius: 4,
                            overflow: 'hidden',
                            '& .MuiInputBase-root': {
                                borderRadius: 4,
                                border: '1px solid',
                                borderColor: grey[400]
                            }
                        }}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon="fa6-solid:user" color="#637381" width="18" height="18" />
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        fullWidth
                        error={!!error}
                        helperText={error ? error?.message : ''}
                        variant="filled"
                        label="Enter your Password"
                        type={showPassword.value ? 'text' : 'password'}
                        sx={{
                            width: '100%',
                            borderRadius: 4,
                            overflow: 'hidden',
                            '& .MuiInputBase-root': {
                                borderRadius: 4,
                                border: '1px solid',
                                borderColor: grey[400]
                            }
                        }}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={showPassword.onToggle}>
                                        <Icon
                                            icon={showPassword.value ? 'mdi:eye-off' : 'mdi:eye'}
                                            color="#637381"
                                            width="25"
                                            height="25"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox checked={remeberMe.value} onChange={remeberMe.onToggle} />
                <Typography>Remeber me</Typography>
            </Stack>
            <Stack width="100%" spacing={2}>
                <LoadingButton
                    loading={isSubmitting}
                    size="large"
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        borderRadius: 4,
                        background: (theme) =>
                            `linear-gradient(138.38deg, ${theme.palette.primary.light} 12.51%, ${theme.palette.primary.main} 154.03%)`
                    }}
                >
                    Sign In
                </LoadingButton>
                <Typography
                    onClick={() => onChangeModal({ loginModal: false, forgotModal: true })}
                    sx={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: 'action.active',
                        '&:hover': { color: 'text.primary' }
                    }}
                >
                    Forgot Password?
                </Typography>

                {config?.IsClientsSideRegistration && (
                    <div style={{ textAlign: 'center' }}>
                        <Typography component="span" variant="body2" sx={{ opacity: 0.8, fontSize: 14 }}>
                            Don't have an account?
                        </Typography>{' '}
                        &nbsp;
                        <Typography
                            component="span"
                            onClick={() => onChangeModal({ registerModal: true, loginModal: false })}
                            noWrap
                            sx={{ fontWeight: 'bold', fontSize: 14, color: 'primary.light', cursor: 'pointer' }}
                        >
                            Register
                        </Typography>
                    </div>
                )}
            </Stack>
        </Stack>
    );
};

export default LoginForm;
