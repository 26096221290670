// @mui
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { grey } from '@mui/material/colors';

// ----------------------------------------------------------------------
export function bgBlur(props) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity)
            }
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity)
    };
}

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden'
}));

export const BackButton = styled(Button)(({ theme }) => ({
    height: 45,
    minWidth: 0,
    padding: 0,
    borderRadius: 8,
    background: '#0095ff',
    '&:hover': {
        background: '#0095ff'
    },
    '&.Mui-disabled': {
        background: '#0095ff',
        opacity: 0.5
    }
}));

export const BackOutButton = styled(Button)(({ theme }) => ({
    height: 45,
    minWidth: 0,
    padding: 0,
    borderRadius: 8,
    background: alpha('#0095ff', 0.6),
    '&:hover': {
        background: '#0095ff'
    },
    '&.Mui-disabled': {
        background: '#0095ff',
        opacity: 0.5
    }
}));

export const LayButton = styled(Button)(({ theme }) => ({
    padding: 0,
    minWidth: 0,
    height: 45,
    borderRadius: 8,
    background: '#c6229b',
    '&:hover': {
        background: '#c6229b'
    },
    '&.Mui-disabled': {
        background: '#c6229b',
        opacity: 0.5
    }
}));

export const LayOutButton = styled(Button)(({ theme }) => ({
    height: 45,
    minWidth: 0,
    padding: 0,
    borderRadius: 8,
    background: alpha('#c6229b', 0.6),
    '&:hover': {
        background: '#c6229b'
    },
    '&.Mui-disabled': {
        background: '#c6229b',
        opacity: 0.5
    }
}));

export const StyledMenu = styled(Paper)(({ theme }) => ({
    ...bgBlur({
        opacity: 0.94,
        color: theme.palette.background.default
    }),
    top: 72,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'grid',
    position: 'absolute',
    alignItems: 'flex-start',
    zIndex: theme.zIndex.modal,
    padding: theme.spacing(5, 1, 1, 3),
    boxShadow: theme.customShadows.dialog,
    maxWidth: theme.breakpoints.values.lg,
    gridTemplateColumns: 'repeat(12, 1fr)',
    borderRadius: Number(theme.shape.borderRadius) * 2,
    border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
    // pointerEvents: 'none',
    '& .MuiPopover-paper': {
        minWidth: 160,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        boxShadow: theme.customShadows.dropdown,
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
        ...bgBlur({ color: theme.palette.success.darker })
    }
}));

export const StyledDepositBtn = styled(Button)(({ theme }) => ({
    borderRadius: 8,
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    outline: 0,
    '&::after': {
        position: 'absolute',
        content: `''`,
        bottom: -4,
        top: -4,
        left: -4,
        right: -4,
        backgroundColor: 'transparent',
        borderRadius: 8,
        border: '2px solid',
        borderColor: theme.palette.primary.light,
        margin: 0,
        opacity: 1,
        transformOrigin: 'center',
        animation: 'anim 800ms linear infinite'
    },
    '&::before': {
        position: 'absolute',
        content: `''`,
        bottom: -4,
        top: -4,
        left: -4,
        right: -4,
        backgroundColor: 'transparent',
        borderRadius: 8,
        border: '2px solid',
        borderColor: theme.palette.primary.light,
        margin: 0,
        opacity: 0.8,
        transformOrigin: 'center',
        animation: 'anim 800ms linear 400ms infinite'
    }
}));

export const StyledEventRow = styled(Stack)(({ theme }) => ({
    p: 1,
    borderRadius: 2,
    userSelect: 'none',
    position: 'relative',
    bgcolor: grey[100],
    '&:hover': {
        '&::after': {
            right: 0,
            bgcolor: '#1d34a91a'
        }
    },
    '&::before': {
        opacity: 0,
        transition: 'all 0.5s ease-out',
        position: 'absolute',
        content: 'attr(data-effect)',
        backfaceVisibility: 'hidden',
        top: 0,
        left: 0
    },
    '&::after': {
        opacity: 1,
        top: 0,
        left: 0,
        right: '100%',
        bottom: 0,
        zIndex: 0,
        borderRadius: 2,
        transition: 'all 0.5s ease-out',
        position: 'absolute',
        content: 'attr(data-effect)',
        backfaceVisibility: 'hidden'
    }
}));
