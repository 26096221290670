import { createRoot } from 'react-dom/client';

import MuiThemeProvider from '../theme';
import ConfigProvider from './config';
import SettingProvider from './setting';
import AuthProvider from './auth';
import SocketProvider from './socket';
import { HelmetProvider } from 'react-helmet-async';

const Root = createRoot(document.getElementById('hattrick247') as HTMLElement);

export { Root, MuiThemeProvider, ConfigProvider, SettingProvider, AuthProvider, SocketProvider, HelmetProvider };
