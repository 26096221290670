import { useEffect, useCallback, useState } from 'react';
// routes
import useAuth from 'hooks/useAuth';
import { useRouter } from 'hooks/use-router';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
    const router = useRouter();

    const { isLogined } = useAuth();
    const [checked, setChecked] = useState(false);

    const check = useCallback(() => {
        if (!isLogined) {
            const href = `/sports`;
            router.replace(href);
        } else {
            setChecked(true);
        }
    }, [isLogined, router]);

    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogined]);

    if (!checked) {
        return null;
    }

    return <>{children}</>;
}
