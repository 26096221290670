// Mui
import Box from '@mui/material/Box';

// components
import GameList from './GameList';
import Slider from 'pages/Home/Slider';

const Spribe = () => {
    return (
        <>
            <Box>
                <Slider />
            </Box>
            <Box sx={{ py: 2 }}>
                <GameList />
            </Box>
        </>
    );
};

export default Spribe;
