import { createSlice } from '@reduxjs/toolkit';
// @types
import { IExchangeProps } from 'types/bet';

// ----------------------------------------------------------------------

const initialState: IExchangeProps = {
    settings: {},
    parentIds: [],
    defaultSetting: {}
};

const exchangeSlice = createSlice({
    name: 'exchange',
    initialState,
    reducers: {
        setEventSettings(state, action) {
            state.settings = action.payload;
        },
        setParentIds(state, action) {
            state.parentIds = action.payload;
        },
        setDefaultSt(state, action) {
            state.defaultSetting = action.payload;
        }
    }
});

export const { setEventSettings, setParentIds, setDefaultSt } = exchangeSlice.actions;
// Reducer
export default exchangeSlice.reducer;
