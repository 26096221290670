import { Link as RouterLink } from 'react-router-dom';
// @mui
import { ButtonBase, Stack, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

// ----------------------------------------------------------------------

export default function NavItem({ openNav, item, depth, open, active, isExternalLink, ...other }: any) {
    const { title, path, count, children, disabled, icon } = item;

    const renderContent = (
        <ButtonBase
            sx={{
                width: 1,
                justifyContent: 'flex-start',
                '& .MuiTouchRipple-root': { zIndex: 1, borderRadius: 1 },
                '&:hover': {
                    '& .item': {
                        '&::after': {
                            right: 0,
                            left: 0
                        }
                    },
                    '& .item-icon': {
                        bgcolor: (theme) =>
                            theme.palette.mode === 'light' ? '#deebff' : theme.palette.background.default
                    }
                }
            }}
        >
            <Stack
                className="item-icon"
                alignItems="center"
                justifyContent="center"
                sx={{
                    bgcolor: (theme) => (theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default),
                    borderRadius: 1,
                    minWidth: 35,
                    height: 35,
                    position: 'relative',
                    zIndex: 1
                }}
            >
                {icon}
            </Stack>
            {openNav && (
                <Stack
                    className="item"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        px: 1,
                        flex: 1,
                        flexGrow: 1,
                        '&::after': {
                            bgcolor: (theme) =>
                                theme.palette.mode === 'light' ? '#deebff' : theme.palette.background.default,
                            boxShadow: (theme) =>
                                theme.palette.mode === 'light'
                                    ? '0px 3px 29px -3px #D9D9D9'
                                    : '0px 3px 29px -3px #515151',
                            borderRadius: 1,
                            opacity: 1,
                            top: 0,
                            left: 0,
                            right: '100%',
                            bottom: 0,
                            color: 'transparent',
                            zIndex: 0,
                            transition: 'all .5s ease-out',
                            position: 'absolute',
                            content: 'attr(data-effect)',
                            backfaceVisibility: 'hidden'
                        }
                    }}
                >
                    <Typography
                        noWrap
                        sx={{ fontSize: 12, fontWeight: 600, position: 'relative', zIndex: 1, color: 'text.primary' }}
                    >
                        {title}
                    </Typography>
                    {Boolean(count) && (
                        <Typography
                            component="span"
                            sx={{
                                fontSize: 10,
                                fontWeight: 600,
                                color: 'primary.default',
                                bgcolor: 'primary.lighter',
                                borderRadius: 1,
                                width: 15,
                                height: 15,
                                position: 'relative',
                                zIndex: 1
                            }}
                        >
                            {count}
                        </Typography>
                    )}
                </Stack>
            )}
        </ButtonBase>
    );

    // ExternalLink
    if (isExternalLink)
        return (
            <Link href={path} target="_blank" rel="noopener" underline="none">
                {renderContent}
            </Link>
        );

    // Has child
    if (children || disabled) {
        return renderContent;
    }

    // Default
    return (
        <Link component={RouterLink} to={path} underline="none" sx={{ width: '100%' }}>
            {renderContent}
        </Link>
    );
}
