import { Theme, alpha } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    position: 'relative'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    [`&.${tableRowClasses.selected}`]: {
                        backgroundColor: alpha(theme.palette.primary.dark, 0.04),
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.dark, 0.08)
                        }
                    },
                    '&:last-of-type': {
                        [`& .${tableCellClasses.root}`]: {
                            borderColor: 'transparent'
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomStyle: 'dashed'
                },
                head: {
                    fontSize: 14,
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.background.neutral
                },
                stickyHeader: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
                },
                paddingCheckbox: {
                    paddingLeft: theme.spacing(1)
                }
            }
        },
        MuiTablePagination: {
            defaultProps: {
                backIconButtonProps: {
                    size: 'small'
                },
                nextIconButtonProps: {
                    size: 'small'
                },
                SelectProps: {
                    MenuProps: {
                        MenuListProps: {
                            sx: {
                                '& .MuiMenuItem-root': {
                                    ...theme.typography.body2
                                }
                            }
                        }
                    }
                }
            },

            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${theme.palette.divider}`
                },
                toolbar: {
                    height: 64
                },
                actions: {
                    marginRight: theme.spacing(1)
                },
                select: {
                    '&:focus': {
                        borderRadius: theme.shape.borderRadius
                    }
                }
            }
        }
    };
}
