// components
import Loader from 'components/Loader';
import Unavailable from 'components/Unavailable';

import { AuthContext, AuthContextValue } from 'contexts/auth';
import { SettingContext } from 'contexts/setting';
import { SettingsContextProps } from 'types/config';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
    return (
        <SettingContext.Consumer>
            {(setting: SettingsContextProps) => {
                if (setting.configured) {
                    if (!setting.avaialbe) {
                        return <Unavailable />;
                    }
                    return (
                        <AuthContext.Consumer>
                            {(auth: AuthContextValue | null) =>
                                auth === null || (auth && auth.authLoading) ? <Loader /> : children
                            }
                        </AuthContext.Consumer>
                    );
                }
                return <Loader />;
            }}
        </SettingContext.Consumer>
    );
}
