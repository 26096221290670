export const checkPageStatus = (message: string, logo?: string | null) => {
    if (!('Notification' in window)) {
        alert('This browser does not support system notifications!');
    } else if (Notification.permission === 'granted') {
        sendNotification(message, logo);
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
            if (permission === 'granted') {
                sendNotification(message, logo);
            }
        });
    }
};

const sendNotification = (message: string, logo?: string | null) => {
    const body: any = {
        body: `${message}`
    };

    if (logo) {
        body.icon = logo;
    }
    const notification = new Notification('Notificatoin', body);
    notification.onclick = () => window.open(`${window.location.origin}/reports/deposit`);
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

export const calcMoney = (amount: number) => {
    if (Math.floor(amount / 1000000) >= 1) {
        return `${amount / 100000}lac`;
    } else if (Math.floor(amount / 10000) >= 1) {
        return `${amount / 1000}k`;
    }
    return amount;
};

export const balanceRound = (amount: number | string) => {
    if (amount) {
        let value = Number(amount);
        value = Math.floor(value * 100) / 100;
        return value.toFixed(2);
    }
    return '0.00';
};
