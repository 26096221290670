// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';

import { Icon } from '@iconify/react';
import { PageNationPerPage } from 'config/constant';
import useResponsive from 'hooks/useResponsive';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'ltr' ? (
                    <Icon icon="icon-park-outline:go-start" />
                ) : (
                    <Icon icon="icon-park-outline:go-end" />
                )}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'ltr' ? <Icon icon="mingcute:left-line" /> : <Icon icon="mingcute:right-line" />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'ltr' ? <Icon icon="mingcute:right-line" /> : <Icon icon="mingcute:left-line" />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'ltr' ? (
                    <Icon icon="icon-park-outline:go-end" />
                ) : (
                    <Icon icon="icon-park-outline:go-start" />
                )}
            </IconButton>
        </Box>
    );
}

type PagenatinProps = {
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (event: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Pagenation({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange }: PagenatinProps) {
    const isMobile = useResponsive('down', 'sm');
    return (
        <TablePagination
            rowsPerPageOptions={PageNationPerPage}
            colSpan={3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={!isMobile && 'Row per page'}
            slotProps={{
                select: {
                    sx: { fontSize: 16 }
                }
            }}
            sx={{ borderTop: 0 }}
            component={'div'}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
        />
    );
}
