import { Icon } from '@iconify/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';

import useSettings from 'hooks/useSettings';
import UserPart from './UserPart';
import { ASSETS } from 'utils/axios';

import sAviator from 'assets/img/header/sAviator.svg';
import plain from 'assets/img/header/plain.svg';
import propeller from 'assets/img/header/propeller.svg';
import { useMemo } from 'react';

const SportsHeader = () => {
    const { navbar, onChangeNav } = useSettings();
    const { config } = useSettings();
    const location = useLocation();

    const list = [
        {
            title: 'Sports',
            link: '/sports'
        },
        {
            title: 'Casino',
            link: '/casino'
        },
        {
            title: 'Promotion',
            link: '/promotion'
        },
        {
            title: 'spribe',
            link: '/spribe'
        }
    ];

    const active = useMemo(() => {
        const index = list.findIndex((item) => location.pathname.startsWith(item.link));
        if (index !== -1) return index;
        return 0;
    }, [list, location.pathname]);

    return (
        <Box
            component="header"
            sx={{
                top: 0,
                zIndex: 100,
                width: '100%',
                position: 'fixed',
                bgcolor: 'background.default'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    px: 2,
                    height: 70,
                    width: '100%'
                }}
            >
                <Stack direction="row" alignItems="center">
                    <RouterLink to="/sports">
                        {config && config.domain.logo && (
                            <Box
                                component="img"
                                src={ASSETS(config.domain.logo)}
                                alt="logo"
                                sx={{ maxWidth: { sm: 170, xs: 130 }, maxHeight: 35 }}
                            />
                        )}
                    </RouterLink>
                    <IconButton sx={{ p: 0 }} onClick={onChangeNav}>
                        {navbar === 'close' ? (
                            <Icon icon="mdi:menu-close" color="#ffab00" width="30" height="30" />
                        ) : (
                            <Icon icon="ic:twotone-menu-open" color="#ffab00" width="30" height="30" />
                        )}
                    </IconButton>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} sx={{ m: 'auto' }}>
                    {list.map((item, i) => (
                        <ButtonBase
                            key={i}
                            color="inherit"
                            component={RouterLink}
                            to={item.link}
                            sx={{
                                px: 2,
                                height: 35,
                                borderRadius: 1,
                                fontWeight: 500,
                                color: 'text.secondary',
                                whiteSpace: 'nowrap',
                                ...(active === i && {
                                    color: 'common.white',
                                    bgcolor: 'primary.main',
                                    '.plain-positon': {
                                        bottom: 0,
                                        opacity: 1
                                    }
                                }),
                                '&:hover': {
                                    bgcolor: 'primary.main',
                                    color: 'common.white',
                                    transition: 'background 0.5s',
                                    '.plain-positon': {
                                        bottom: 0,
                                        opacity: 1
                                    }
                                }
                            }}
                        >
                            {item.title === 'spribe' ? (
                                <>
                                    <Box component="img" src={sAviator} alt="item" sx={{ width: 67 }} />
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            opacity: 0,
                                            bottom: -20,
                                            transition: 'opacity 0.5s, bottom 0.5s '
                                        }}
                                        className="plain-positon"
                                    >
                                        <Box className="plain-opacity">
                                            <Box
                                                component="img"
                                                src={plain}
                                                alt="item"
                                                sx={{ width: 26, position: 'relative', top: 3 }}
                                            />
                                            <Box
                                                component="span"
                                                sx={{
                                                    fill: '#ff779c',
                                                    position: 'absolute',
                                                    right: -3,
                                                    top: -4,
                                                    width: 3,
                                                    height: 16,
                                                    backgroundImage: `url(${propeller})`,
                                                    backgroundSize: 'cover',
                                                    transform: 'rotate(-25deg)',
                                                    animation: 'propeller .1s infinite'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                item.title
                            )}
                        </ButtonBase>
                    ))}
                </Stack>
                <UserPart />
            </Stack>
        </Box>
    );
};

export default SportsHeader;
