import { useEffect, useCallback, useState } from 'react';
// routes
import useAuth from 'hooks/useAuth';
import { useRouter } from 'hooks/use-router';
import { useParams } from 'react-router-dom';
import { sportDataById } from 'config/constant';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function SportsGuard({ children }: Props) {
    const params = useParams();
    const router = useRouter();

    const { isLogined, user } = useAuth();

    const [checked, setChecked] = useState(false);

    const check = useCallback(() => {
        if (isLogined && user && params.sportId) {
            const keys = Object.keys(user.permissions);
            const blocked = keys.filter(function (key) {
                return user.permissions[key] === false;
            });
            const currentSport = sportDataById[params.sportId];
            const idx = blocked.findIndex((key: string) => key === currentSport.name);
            if (idx === -1) {
                setChecked(true);
            } else {
                const href = `/sports`;
                router.replace(href);
            }
        } else {
            setChecked(true);
        }
    }, [isLogined, user, params, router]);

    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!checked) {
        return null;
    }

    return <>{children}</>;
}
