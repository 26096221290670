import { createContext } from 'react';
import { ModeValue, NavValue, SettingsContextProps } from 'types/config';

const defaultSettings: {
    loginModal: boolean;
    registerModal: boolean;
    passwordModal: boolean;
    forgotModal: boolean;
    navbar: NavValue;
    mode: ModeValue;
} = {
    loginModal: false,
    registerModal: false,
    passwordModal: false,
    forgotModal: false,
    navbar: 'open',
    mode: 'light'
};

const initialState: SettingsContextProps = {
    ...defaultSettings,
    configured: false,
    avaialbe: true,
    config: null,
    game: {
        open: false,
        title: '',
        link: ''
    },
    onChangeNav: () => {},
    onChangeTheme: (mode: ModeValue) => {},
    onChangeModal: () => {},
    onChangeGameModal: () => {},
    onChangePasswordModal: () => {}
};
const SettingContext = createContext<SettingsContextProps>(initialState);

export { SettingContext, initialState, defaultSettings };
