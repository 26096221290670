import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import useAuth from 'hooks/useAuth';
import { spribeGameList, spribeLaunch, spribeOperatorKey } from 'config/constant';

const Aviator = () => {
    const { game } = useParams();

    const { isLogined, user, accessToken } = useAuth();
    const navigate = useNavigate();
    const [link, setLink] = useState('');

    useEffect(() => {
        if (spribeGameList.findIndex((item) => item.game === game) === -1) {
            navigate('/spribe');
        }
        if (accessToken && isLogined) {
            setLink(
                `${spribeLaunch}/${game}?user=${user._id}&token=${accessToken}&lang=en&currency=INR&operator=${spribeOperatorKey}&return_url=https://hattrick247.com/spribe`
            );
        } else {
            navigate('/spribe');
        }
    }, [isLogined, game]);

    return (
        <Box sx={{ pt: 2 }}>
            <Container maxWidth="xl" sx={{ px: 0 }}>
                <iframe id="spribe" title="spribe" width="100%" style={{ height: '85vh', border: 0 }} src={link} />
            </Container>
        </Box>
    );
};

export default Aviator;
