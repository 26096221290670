// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

import logoIcon from 'assets/img/logo/logo.png';
// hook
import useSettings from 'hooks/useSettings';
// utils
import { ASSETS } from 'utils/axios';
//
import loginImg from 'assets/img/auth/login-img.png';
import loginDarkImg from 'assets/img/auth/login-img-dark.png';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotForm from './ForgotPasswordForm';
import useResponsive from 'hooks/useResponsive';

const LoginModal = () => {
    const { mode, loginModal, registerModal, forgotModal, onChangeModal, config } = useSettings();
    const isTablate = useResponsive('up', 'sm');

    const closeModal = () => {
        onChangeModal({ loginModal: false, registerModal: false, forgotModal: false });
    };

    return (
        <Dialog
            maxWidth="md"
            open={loginModal || registerModal || forgotModal}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 4,
                    bgcolor: 'background.paper'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Box sx={{ py: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        src={ASSETS(config?.domain.logo || logoIcon)}
                        alt="logo"
                        sx={{ maxWidth: 200 }}
                    />
                </Box>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                    {isTablate && (
                        <Stack sx={{ width: '50%' }}>
                            <Box
                                component="img"
                                src={mode === 'light' ? loginImg : loginDarkImg}
                                alt="login"
                                sx={{ px: 2 }}
                            />
                            <Stack sx={{ mt: 1 }}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        color: mode === 'light' ? 'primary.main' : 'primary.light',
                                        fontSize: 22,
                                        fontWeight: 700,
                                        fontStyle: 'italic'
                                    }}
                                >
                                    100% &nbsp;
                                    <strong>Fast</strong>
                                    &nbsp;Withdrawal
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    {(() => {
                        if (loginModal) return <LoginForm />;
                        if (registerModal) return <RegisterForm />;
                        if (forgotModal) return <ForgotForm />;
                    })()}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default LoginModal;
