import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import ScrollToTop from 'components/ScrollToTop';
import LoginModal from 'components/Modals/Login';
import ChangePassword from 'components/Modals/ChangePassword';
import CasinoDialog from 'components/Modals/CasinoDialog';

function App() {
    //     const charAt = `

    //     ██████ ██    ██    ████   ██    ██
    //     ▒▒      ▒▒  ▒▒   ▒▒    ▒▒ ▒▒    ▒▒
    //     ▒▒▒▒▒▒    ▒▒    ▒▒        ▒▒▒▒▒▒▒▒
    //     ▒▒      ▒▒  ▒▒   ▒▒    ▒▒ ▒▒    ▒▒
    //     ██████ ██    ██    ████   ██    ██

    // `;
    //     console.clear();
    //     console.info(`%c${charAt}`, 'color: red;font-size:20px;font-weight:bold');
    //     console.info(
    //         `%c Warning! - Someone may steal your account info or something that is important by running a script. Don't run any script if you can't trust and understand how it works.`,
    //         'color: red;font-size:16px;font-weight:bold'
    //     );
    return (
        <BrowserRouter>
            <LoginModal />
            <ChangePassword />
            <CasinoDialog />
            <ScrollToTop />
            <Routes />
        </BrowserRouter>
    );
}

export default App;
