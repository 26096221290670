import { useEffect, useState } from 'react';
// Mui
import Box from '@mui/material/Box';

import useResponsive from 'hooks/useResponsive';
import useAuth from 'hooks/useAuth';
import { auraKey } from 'config/constant';

const Poker = () => {
    const isDesktop = useResponsive('up', 'sm');
    const { isLogined } = useAuth();
    const [link, setLink] = useState('');

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isLogined) {
            setLink(`https://${isDesktop ? 'd2' : 'm2'}.fawk.app/#/splash-screen/${accessToken}|hattrick/${auraKey}`);
        } else {
            setLink(`https://${isDesktop ? 'd2' : 'm2'}.fawk.app/#/splash-screen`);
        }
    }, [isDesktop, isLogined]);

    return (
        <Box sx={{ pb: 1 }}>
            <Box
                component="iframe"
                id="poker"
                title="poker"
                width="100%"
                sx={{ height: '85vh', border: 0, borderRadius: 2 }}
                src={link}
            />
        </Box>
    );
};

export default Poker;
